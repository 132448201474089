@keyframes pulsiveUp {
    from {
        bottom: 30px;
        opacity: 0.7;
    }
    to {
        bottom: 50px;
        opacity: 0;
    }
}
@keyframes pulsiveDawn {
    from {
        bottom: 30px;
        opacity: 0.7;
    }
    to {
        bottom: 10px;
        opacity: 0;
    }
}
@-webkit-keyframes pulsiveUp {
    from {
        bottom: 30px;
        opacity: 0.7;
    }

    to{
        bottom: 50px;
        opacity: 0;
    }
}
@-webkit-keyframes pulsiveDawn {
    from{
        bottom: 30px;
        opacity: 0.7;
    }
    to{
        bottom: 10px;
        opacity: 0;
    }
}
@-moz-keyframes pulsiveUp {
    from {
        bottom: 30px;
        opacity: 0.7;
    }
    to {
        bottom: 50px;
        opacity: 0;
    }
}
@-moz-keyframes pulsiveDawn {
    from {
        bottom: 30px;
        opacity: 0.7;
    }
    to {
        bottom: 10px;
        opacity: 0;
    }
}
@-ms-keyframes pulsiveUp {
    from {
        bottom: 30px;
        opacity: 0.7;
    }
    to {
        bottom: 50px;
        opacity: 0;
    }
}
@-ms-keyframes pulsiveDawn {
    from {
        bottom: 30px;
        opacity: 0.7;
    }
    to {
        bottom: 10px;
        opacity: 0;
    }
}
@keyframes blueMove {
    0%{margin-top: 0}
    50%{margin-top:1.25rem}
    100%{margin-top: 0}
}
@-webkit-keyframes blueMove {
    0%{margin-top: 0}
    50%{margin-top:1.25rem}
    100%{margin-top: 0}
}
@-moz-keyframes blueMove {
    0%{margin-top: 0}
    50%{margin-top:1.25rem}
    100%{margin-top: 0}
}
@-ms-keyframes blueMove {
    0%{margin-top: 0}
    50%{margin-top:1.25rem}
    100%{margin-top: 0}
}
.arrowD{
    cursor: pointer;
    position: absolute;
    max-height: 38px;
    bottom: 30px;
    max-width: 130px;
    width: 100%;
    height: 100%;
}
.arrowD-pulsiveUp{
    cursor: pointer;
    position: absolute;
    bottom: 30px;
    -moz-animation:pulsiveUp 1000ms infinite;
    -o-animation:pulsiveUp 1000ms infinite;
    -webkit-animation: pulsiveUp 1000ms infinite;
    animation: pulsiveUp 1000ms infinite;
}
.arrowD-pulsiveDawn{
    cursor: pointer;
    position: absolute;
    bottom: 30px;
    -moz-animation:pulsiveDawn 1000ms infinite;
    -o-animation:pulsiveDawn 1000ms infinite;
    -webkit-animation: pulsiveDawn 1000ms infinite;
    animation: pulsiveDawn 1000ms infinite;
}

.arrowBlue{
    position: absolute;
    top: 190px;
    z-index: 10;
    cursor: pointer;
    margin-bottom: 1rem;
}
.open .arrowBlue{
    transform: rotate(180deg);
    transition-duration: .3s;
    transition-timing-function: ease;
}
.close .arrowBlue{
    transition-duration: .3s;
    transition-timing-function: ease;
    animation: blueMove 1500ms infinite;
}
.map, .email, .phone{
    width: 4.375rem;
    height: 4.375rem;
}

.mainSvg{
    width: 100%;
    height: 100%;
}
@media screen and (max-width: 1320px){
    .mainSvg{
        width: 90%;
        height: 90%;
    }
    .arrowD, .arrowD-pulsiveDawn, .arrowD-pulsiveUp{
        max-height: 29px;
        max-width: 100px;
    }
    .arrowBlue{
        width: 5rem;
        height: 2.1875rem;
        top: 150px;
    }
}
@media screen and (max-width: 824px){
    .arrowD, .arrowD-pulsiveDawn, .arrowD-pulsiveUp{
        max-height: 24px;
        max-width: 80px;
    }
    .arrowBlue{
        top: 135px;
    }
}
@media screen and (max-width: 424px){
    .arrowD, .arrowD-pulsiveDawn, .arrowD-pulsiveUp{
        max-height: 18px;
        max-width: 60px;
    }
    .arrowBlue{
        top: 100px;
    }
}