* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.App{
    max-width: 1920px;
    width: 100%;
    height: 100%;
}
html{
    font-size: 16px;
}
body{
    font-family: 'Montserrat', serif;
    font-weight: 400;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.satellite{
    display: flex;
    align-items: center;
    justify-content: center;
}
ul{
    text-transform: uppercase;
}
canvas{
    width: 100%!important;
    height: 100%!important;
}
@media screen and (max-width: 1320px){
    html{
        font-size: 12px;
    }
}
@media screen and (max-width: 824px){
    html{
        font-size: 10px;
    }
}
@media screen and (max-width: 424px){
    html{
        font-size: 8px;
    }
}