/*--------------------MAIN---------------------*/
.main{
    overflow-x: hidden;
    color: white;
    margin-top: 6.25rem;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.main_moon{
    width: 1050px;
    height: 1050px;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: -660px;
}
.main_title{
    margin-top: 1.875rem;
    font-size: 3.125rem;
    font-weight: 500;
    margin-bottom: 2.5rem;
    display: flex;
    justify-content: center;
}
.main_text{
    text-align: center;
    line-height: 2.5rem;
    max-width: 39.375rem;
    margin: 0 auto;
    font-size: 1.2rem;
}
.main_text p:not(:last-child){
    margin-bottom: 1.875rem;
}
.main_about-comp{
    z-index: 1;
    margin-top: 5.625rem;
}
/*--------------------SERVICES---------------------*/
.services_title{
    max-width: 70.375rem;
    font-weight: 500;
    line-height: 160%;
    text-align: center;
    font-size: 3.125rem;
}
.services_text{
    font-weight: 500;
    margin-left: 7.5rem;
    font-size: 1.25rem;
    max-width: 37.5rem;
}
.services_text-item{
    line-height: 170%;
}
.services_text-item:not(:last-child){
    margin-bottom: 2.4rem;
}
.services_scheme{
    margin: 0 auto;
    width: 30.125rem;
    height: 30.125rem ;
}
.services_block{
    padding: 0 10px;
    margin-top: 6.8rem;
}
/*--------------------MICRO---------------------*/
.micro{
    padding: 0 25px;
    margin-top: 8.125rem;
    display: flex;
    align-items: start;
    flex-direction: column;
}
.micro_title{
    margin-bottom: 1.25rem;
    line-height: 160%;
    font-size: 3.125rem;
    font-weight: 400;
}
.micro_text-block{
    font-size: 1.25rem;
    max-width: 54.5rem;
    margin-right: 7.3rem;
}
.micro_text-item{
    font-weight: 500;
    line-height: 170%;
}
.micro_text-item:not(:last-child){
    margin-bottom: 2rem;
}
.micro_text{
    line-height: 185%;
    margin-bottom: 1.5rem;
}
.micro_img{
    width: 31.125rem;
    height: 31.125rem;
}
.micro_block, .services_block{
    display: flex;
    justify-content: center;
    align-items: center;
}
/*--------------------PRODUCTS---------------------*/
.products{
    margin-top: 6.25rem;
}
.products_title{
    text-align: center;
    font-size: 3.125rem;
}
.products_text{
    text-align: center;
    line-height: 2.875rem;
    font-size: 1.25rem;
}
.products_silterra, .products_angstrem, .main_services, .products_mikron{
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
}
.products_silterra-img{
    width: 22.06rem;
    height: 3.625rem;
    margin: 5.9rem 0 2.5rem 0;
}
.products_mikron-img{
    width: 22.06rem;
    height: 5.5rem;
}
.products_angstrem-img{
    width: 27.56rem;
    height: 3.9375rem;
}
.products_mikron-img, .products_angstrem-img{
    margin: 3.9rem 0 1.6rem 0;
}
.products_silterra-text{
    display: flex;
    margin: 3.125rem auto 0 auto;
}
.products_silterra-item{
    font-size: 1.25rem;
}
.products_silterra-item:not(:last-child){
    margin-bottom: 1.4rem;
    line-height: 2rem;
}
.products_silterra_block:first-child{
    max-width: 37.5rem;
}
.products_silterra_block:last-child{
    max-width: 32.5rem;
    margin-left: 4rem;
}
.open .products_silterra-text{
    max-height: 70rem;
    transition-duration: .7s;
    transition-timing-function: ease;
    transition-property: max-height;
    overflow: hidden;
}
.close .products_silterra-text{
    max-height: 0;
    transition-duration: .6s;
    transition-timing-function: ease;
    transition-property: max-height;
    overflow: hidden;
}
/*--------------------MICROCHIP---------------------*/
.microchip h3{
    font-size: 1.75rem;
    font-weight: 400;
    margin-bottom: 1.25rem;
}
.microchip_title{
    margin: 7rem 0 4.5rem 0;
    font-size: 4rem;
    text-align: center;
}
.microchip_functions-li{
    font-size: 1.25rem;
    line-height: 2rem;
}
.microchip_functions-block, .microchip_specifications-ul{
    max-width: 24.125rem;
}
.microchip_functions{
    margin: 0 3.5rem 0 10rem;
}
.microchip_functions-li:not(:last-child){
    margin-bottom: 1.25rem;
}
.microchip_specifications-li:not(:last-child){
    margin-bottom: 1.5rem;
}
.microchip_img{
    width: 22.75rem;
    height: 28.937rem;
}
.microchip_block{
    font-size: 1.25rem;
    display: flex;
    align-items: center;
}
.microchip_text-block{
    display: flex;
    justify-content: center;
}
.microchip_p{
    font-size: 1.375rem;
    font-weight: 500;
    margin: 2rem 0 0 32.74rem;
}
.microchip{
    padding: 20px;
}
@media screen and (max-width: 1320px){
    .main_moon{
        width: 700px;
        height: 700px;
        left: -480px;
        top: 10vh;
    }
    .main_about-comp{
        margin: auto 0;
    }
    .microchip_p{
        margin-top: 2.1rem;
    }
}
@media screen and (max-width: 824px){
    .main_moon{
        width: 500px;
        height: 500px;
        left: -250px;
        top: 20vh;
    }
    .services_text{
        margin-left: 1.5rem;
    }
    .services_block, .micro_block, .microchip_block{
        align-items: center;
        flex-direction: column;
    }
    .services_scheme, .micro_text-block{
        margin: 0 0 5.1254rem 0;
    }
    .products_title{
        font-size: 2.7rem;
    }
    .main{
        margin-top: 0;
    }
    .microchip_p{
        margin: 2.6rem 0 0 0;
        text-align: center;
    }
    .microchip_img{
        margin: 0 6rem 4rem 0;
    }
    .microchip_functions{
        margin-left: 0;
    }
}
@media screen and (max-width: 424px){
    .main_moon{
        width: 400px;
        height: 400px;
        left: -250px;
        top: 12vh;
    }
    .products_title{
        font-size: 2rem;
    }
}