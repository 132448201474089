.footer{
    white-space: nowrap;
    margin-top: 5.25rem;
    padding: 0 5.625rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
}
a{
    text-decoration: none;
    color: white;
}
.footer_contact-text{
    margin-left: 1.25rem;
    font-size: 1.375rem;
    font-weight: 500 !important;

}
.footer_contact-text:hover{
    -webkit-text-stroke: 0.05rem white;
}
.footer_contact:not(:last-child){
    margin-bottom: 0.9375rem;
}
.footer_title-text{
    font-size: 2.2rem;
}
.footer_icon{
    width: 15rem;
    height: 16.6875rem;
}
.footer_container{
    display: flex;
    align-items: center;
}
.footer_title{
    width: 100%;
    display: flex;
    align-items: end;
}
.footer_contacts{
    margin-top: 4.375rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
}
.footer_contact{
    display: flex;
    align-items: center;
}
.footer_title-line{
    height: 1px;
    width: 42%;
    background: rgba(252, 252, 252, 0.8);
}
@media screen and (max-width: 1320px){
    .footer{
        padding: 0 2rem;
    }
}
@media screen and (max-width: 824px){
    .footer{
        padding: 10px;
        margin-bottom: 10px;
    }
    .footer_container{
        align-items: center;
        white-space: normal;
        line-height: 20px;
    }
    .footer_title-line{
        display: none;
    }
}
@media screen and (max-width: 424px){
    .footer_icon{
        margin-top: 20px;
    }
}